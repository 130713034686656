body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body.start-page {
  background-image: url("./img/start-page-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 30%;
}

@font-face {
  font-family: "airbnb_cereal_appblack";
  src: url("./fonts/airbnbcerealblack-webfont.woff2") format("woff2"), url("./fonts/airbnbcerealblack-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "airbnb_cereal_appbold";
  src: url("./fonts/airbnbcerealbold-webfont.woff2") format("woff2"), url("./fonts/airbnbcerealbold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "airbnb_cereal_appextrabold";
  src: url("./fonts/airbnbcerealextrabold-webfont.woff2") format("woff2"), url("./fonts/airbnbcerealextrabold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "airbnb_cereal_appbook";
  src: url("./fonts/airbnbcerealbook-webfont.woff2") format("woff2"), url("./fonts/airbnbcerealbook-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "airbnb_cereal_appmedium";
  src: url("./fonts/airbnbcerealmedium-webfont.woff2") format("woff2"), url("./fonts/airbnbcerealmedium-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "airbnb_cereal_applight";
  src: url("./fonts/airbnbcereallight-webfont.woff2") format("woff2"), url("./fonts/airbnbcereallight-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}